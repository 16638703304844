@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&display=swap');

html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
}

* {
  font-family: 'Nunito Sans', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.shepherd-button-primary {
  background-color: #C9177E;
  color: white;
}

.shepherd-button-primary:hover {
  background-color: #8A185A;
}
